import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';


/*Material modules*/
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatListModule,
  MatRadioModule,
  MatSlideToggleModule,
  MatStepperModule,
  MatTooltipModule
} from '@angular/material';


import { ToastModule } from 'primeng/toast';
import { FieldsetModule } from 'primeng/fieldset';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import {
  AccordionModule,
  AutoCompleteModule,
  CalendarModule,
  CheckboxModule,
  ConfirmationService,
  DropdownModule,
  EditorModule, FileUploadModule,
  InputMaskModule,
  InputTextModule,
  MessageModule, MessagesModule, OverlayPanelModule,
  PaginatorModule,
  PanelModule,
  RadioButtonModule,
  SharedModule,
  TabViewModule,
  TooltipModule
} from 'primeng/primeng';


import {
  FileUploadService,
  GenericAlertService,
  MpFileUploadModule,
  MpMessageWithUploadModule,
  MpPdfViewerModule,
  PipesModule,
  SpaModule
} from '@mpng/home';
import { LoggerModule, LogLevel } from '@mpng/logger';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DeviceDetectorModule } from 'ngx-device-detector';


import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';

import {PareceresSessaoComponent} from './pareceres-sessao/pareceres-sessao.component';
import {FileDownloaderComponent} from './file-downloader/file-downloader.component';
import {LoadingInterceptor} from './interceptor/loading-interceptor';
import {PautaComponent} from './pauta/pauta.component';
import {MetaComponent} from './meta/meta.component';


@NgModule({
  declarations: [
    AppComponent,
    PautaComponent,    
    MetaComponent, 
    PareceresSessaoComponent,
    FileDownloaderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SpaModule.forRoot(),
    PipesModule,
    HttpClientModule,
    ToastModule,
    FieldsetModule,
    ConfirmDialogModule,
    CardModule,
    AccordionModule,
    CheckboxModule,
    TabViewModule,
    DialogModule,
    RadioButtonModule,
    DropdownModule,
    CalendarModule,
    TableModule,
    PaginatorModule,
    LoggerModule.forRoot({level: LogLevel.All}),
    EditorModule,
    InputMaskModule,
    MpPdfViewerModule,
    TooltipModule,
    MessageModule,
    InfiniteScrollModule,
    CommonModule,
    PanelModule,
    InputTextModule,
    SharedModule,
    AutoCompleteModule,
    MpMessageWithUploadModule,
    FileUploadModule,
    MpFileUploadModule,
    MessagesModule,
    OverlayPanelModule,
    DeviceDetectorModule.forRoot(),


    /*Material modules*/
    MatIconModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatDialogModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatListModule,
    MatStepperModule,
    MatCheckboxModule,
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    }
  ],

  entryComponents: [
 //   ComprovacaoAuxilioEducacaoAvisoComponent,
  ],

  exports: [
  //  ListaAnexoComponent,
  //  ComprovacaoAuxilioEducacaoAvisoComponent,
  //  ComprovacaoAuxilioEducacaoListPendenciaComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
