import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpRequest, HttpHandler, HttpInterceptor, HttpEvent, HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment  } from '../../environments/environment';


@Injectable()
export class LoadingInterceptor implements HttpInterceptor {



  constructor(private spinner: NgxSpinnerService, ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // somente ativamos o loading em producao..
    if (environment.production) {
       this.spinner.show();
    }
    return next.handle(req).pipe(tap(event => {
        if (event instanceof HttpResponse) {
          if (environment.production) {
            this.spinner.hide();
          }
        }
      },(err: any) => {
        if (environment.production) {
          this.spinner.hide();
        }
        console.log(err)
      }));

  }
}
