import { FormsModule  } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '../../environments/environment';



@Component({
  selector: 'pauta',
  templateUrl: './pauta.component.html',  
})
export class PautaComponent implements OnInit {    

  idSessaoSelecionado:string;
  tipoSessaoSelecionada:string
  constructor() { 
    
  }    

  ngOnInit() {        
    
  }

  selecionarSessao(event:Map<string,string>) {      
      this.idSessaoSelecionado = event.get('id');
      this.tipoSessaoSelecionada = event.get('tipoSessao');
  }
}