import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { map } from 'rxjs/operators';


/**
 * Componente que possibilita a abertura de documentos PDF, passando o TOKEN
 * para o back-end.
 * <file-downloader
 *  href="url descricao="abc">
 * </file-downloader>
 */
@Component({
  selector: 'file-downloader',
  template: `
    <a 
       href="javascript:void(0)"
       (click)="onClick()">
      <mat-icon aria-label="Download do zip">cloud_download</mat-icon>
    </a>
  `
})
export class FileDownloaderComponent implements OnInit {
  @Input()
  href = '';
  @Input()
  descricao = '';

  constructor(private httpClient: HttpClient) {
  }

  ngOnInit() {
    console.debug(this.href);
  }

  onClick() {
    this.httpClient
      .get(this.href, {responseType: 'blob'}).pipe(
        map((blob) => {
          return new Blob([blob], {type: 'application/octet-stream'});
        })
      )
      .subscribe(
        (pdf) => {
          FileSaver.saveAs(pdf, this.descricao );
        },
        error => console.log(error)
      );
  }
}
