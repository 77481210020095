import { Component, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import { AppInfo } from '@mpng/home';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {

  public appInfo: AppInfo =

    {
      name: 'Sessões de Julgamento',
      context: '/pauta',
      icon: 'mp-icon mp-prh',
    };

    title = 'app';
    

  constructor(
    private router: Router
  ) {}

  ngAfterViewInit() {
    this.router.navigate(['/login']);
  }

}

