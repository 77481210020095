import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Injectable} from '@angular/core'



@Injectable()
export class PareceresSessaoService {    

    constructor(private httpClient: HttpClient) { 
        
    }  

    buscarPareceresSessao(): Observable<any> {              
        return this.httpClient.get<any>(`/api/pareceresSessao`);
    }       
    
    downloadZip(id:number): Observable<any> {        
        console.log(`Chamando /api/pareceresSessao/${id}`);      
        return this.httpClient.get<any>(`/api/pareceresSessao/${id}`);
    }   
}
