import { FormsModule  } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter , Inject} from '@angular/core';
import { environment } from '../../environments/environment';
import { MetaService } from './meta.service';



@Component({
  selector: 'metaInfo',
  templateUrl: './meta.component.html',
  providers:[MetaService],    
})
export class MetaComponent implements OnInit {     
  
    activeProfiles:any;
    groupId:string;
    artifactId:string;
    version:string;

  constructor(private metaService:MetaService) {

  }

  ngOnInit() {           
    this.meta();
  }    

  meta() {    
    this.metaService.meta().subscribe(data => {                       
        this.activeProfiles = data.activeProfiles;                            
        this.groupId = data.groupId;                            
        this.artifactId = data.artifactId;                            
        this.version = data.version;                            
      }, err => {      
        this.activeProfiles = null;                            
        this.groupId = null;                            
        this.artifactId = null;                            
        this.version = null; 
      });  
  }   
  
}