import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Injectable} from '@angular/core'



@Injectable()
export class MetaService {   
    
    constructor(private httpClient: HttpClient) { 
        
    }  

    meta(): Observable<any> {              
        return this.httpClient.get<any>(`/api/meta`);
    }       
    

}
