import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService, LoginComponent } from '@mpng/home';
import {PautaComponent} from './pauta/pauta.component';

const appRoutes: Routes = [
	
	{
		path: 'login',
		component: LoginComponent,
		data: {title: 'Login', icon: 'mdi mdi-login', noMenu: true}
	},
	{
		path: '',
		redirectTo: '/pauta',
		pathMatch: 'full'
	},
	{
		path: 'pauta',
		component: PautaComponent,
		canActivate: [ AuthGuardService ],
		data: { title: 'Sessões de Julgamento', info: '', noMenu: true, roles: [ 'operador' ] }
	}
];

@NgModule({
	imports: [RouterModule.forRoot(appRoutes, {useHash: true})],
	exports: [RouterModule],
	declarations: [],
  })
export class AppRoutingModule { }
