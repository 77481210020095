import { FormsModule  } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '../../environments/environment';
import { PareceresSessaoService } from './pareceres-sessao.service';
import {FileDownloaderComponent} from '../file-downloader/file-downloader.component';

@Component({
  selector: 'pareceres-sessao',
  templateUrl: './pareceres-sessao.component.html',
  providers:[PareceresSessaoService],    
})
export class PareceresSessaoComponent implements OnInit { 
  
 
  sessaoSelecionada:any;
  pareceresSessaoList = [];  
  

  constructor(private pareceresSessaoService:PareceresSessaoService) { 
    
  }    

  ngOnInit() {        
    this.buscarPareceresSessao();
  }

  buscarPareceresSessao() {
    this.pareceresSessaoService.buscarPareceresSessao().subscribe(data => {                       
      data.map(elm => {        
        this.pareceresSessaoList.push(elm)   
      });                        
    }, err => {      
      this.pareceresSessaoList = []               
    });  
  }
  
  downloadZip() {   
    console.log("Passei");    
    let zipFile;
    this.pareceresSessaoService.downloadZip(91)
        .subscribe(data => zipFile = new Blob([data], { type: "application/octet-stream" }), //console.log(data),
                    error => console.log("Error downloading the file."),
                    () => console.log('Completed file download.'));

    let url = window.URL.createObjectURL(zipFile);
    window.open(url);
  }


 

}